import React from 'react';
import { Marker } from '@react-google-maps/api';
import { extractGoogleDriveFileId, getGoogleDriveThumbnail } from '../global/GlobalUtils';
import { useSelector } from 'react-redux';
import { frontStyleLvzSelector } from './state/frontStyleLvzSelector';

const CustomMarker = ({ position, iconUrl, description}) => {
    const frontStyleLvz = useSelector(frontStyleLvzSelector);
    if (!position || !iconUrl) {        
        return null
    };
    
    // Define size based on zoom level
    let size = 40;
    if (frontStyleLvz < 2) {
        size = 20;
    } else if (frontStyleLvz < 4) {
        size = 25;
    }
    // Extract and transform the icon URL
    const fileId = extractGoogleDriveFileId(iconUrl);
    const fixedIconUrl = getGoogleDriveThumbnail(fileId) || iconUrl;

    const customIcon = {
        url: fixedIconUrl,
        scaledSize: new window.google.maps.Size(size, size),
    };

    return (
        <Marker
            position={position}
            icon={customIcon}
        />
    );
};

export default CustomMarker;
