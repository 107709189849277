import React from 'react'
import { useSelector } from 'react-redux'
import { Marker, OverlayView } from '@react-google-maps/api'

//REDUX SELECTOR
import { frontStyleLvzSelector } from './state/frontStyleLvzSelector'

//UTILS
import * as global from '../global/globalVariable'
import { zoomOnClickedLvz, displayLvzDetailPanel} from '../global/LvzUtils';

//JSON
import jsonZoomUIStatuses from '../zoom/jsonZoomUIStatuses.json'

function LiveViewZoneMarkerWithNumber({position, availability, freeSlot, map, zoneId }) {

    const frontStyleLvz = useSelector(frontStyleLvzSelector)

    let visible = true
    let numberVisible = true
    let markerWidth = jsonZoomUIStatuses["liveviewZone"].icons[frontStyleLvz].size.width
    let markerHeight = jsonZoomUIStatuses["liveviewZone"].icons[frontStyleLvz].size.height

    let image = {
        url: `images/lvzMarker/lvz-marker-${global.lvzColors[availability].pictureKey}.png`,
        scaledSize: new window.google.maps.Size(markerWidth, markerHeight)
    };

    let textColor = global.lvzColors[availability].fillColor
    if(availability === "deactivate"){
        textColor = "transparent"
    }


    if(frontStyleLvz === 3){
        visible = true
        numberVisible = true
    } else {
        visible = false
        numberVisible = false
    }

    let textStyle ={
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "center",
        color: textColor
    }
    function clickOnLvzMarker(){
        // zoomOnClickedLvz(map, position)
        displayLvzDetailPanel(zoneId)
    }

    return (
        <div>
            <Marker
                position= {position}
                icon={image}
                onClick={clickOnLvzMarker}
                visible={visible}
                zIndex={100}
            />
            <OverlayView
                position={{lat : position.lat, lng : position.lng}}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -32
                })}
            >
                <div style={{height: "40px", width: "30px", visibility: numberVisible ? "visible" : "hidden"}}>
                <p style={textStyle}>{freeSlot}</p>
                </div>
            </OverlayView>
        </div>
    )
}

export default LiveViewZoneMarkerWithNumber
