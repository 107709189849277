import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch} from 'react-redux'
import { GoogleMap, TrafficLayer } from "@react-google-maps/api";
import { CSSTransition } from "react-transition-group";

//COMPONENTS
import UserLocationMarker from '../markers/UserLocationMarker'
import ParkingSpecificDetailsPanel from "../detailsPanel/ParkingSpecificDetailsPanel";
import AskGeolocationPanel from "../backToUserLocation/AskGeolocationPanel";
import LvzSpecificDetailsPanel from "../detailsPanel/LvzSpecificDetailsPanel";
import WrapperParkingMarkers from "../markers/WrapperParkingMarkers";
import WrapperSparkedCocoMarkers from "../markers/WrapperSparkedCocoMarkers";
import WrapperPmrMarker from "../markers/WrapperPmrMarker"
import WrapperEvhMarker from "../markers/WrapperEvhMarker";
import WrapperOnstreetCommonMarker from "../markers/WrapperOnstreetCommonMarker";
import WrapperOnstreetDeliveryMarker from "../markers/WrapperOnstreetDeliveryMarker";
import DetailsPanelContainer from '../detailsPanel/DetailsPanelContainer';
import DirectionToDestinationPolyline from "../markers/DirectionToDestinationPolyline";
import CocoSearchMarker from "../markers/CocoSearchMarker";
import FiltersContainer from "../filters/FiltersContainer";
import NavigationSideBar from "../navigation/NavigationSideBar";
import CityInformationsDetailsPanel from "../detailsPanel/CityInformationsDetailsPanel";
import NavBar from '../navBar/NavBar'
import TimeToParkContainer from "../sparkedMode/TimeToParkContainer";
import SparkedDetailsPanel from "../sparkedMode/SparkedDetailsPanel";
import ParkingInProgressPopup from "../popup/ParkingInProgressPopup";
import SparkedMarker from '../markers/SparkedMarker'
import WrapperFreedSpotMarker from "../markers/WrapperFreedSpotMarker";
import TorchMarkerItinerary from "../itinerary/TorchMarkerItinerary";
import ItineraryPanel from "../itinerary/ItineraryPanel";
import DestinationNotReachYet from "../itinerary/DestinationNotReachYet";
import ItineraryOverviewToGuidanceScreen from "../itinerary/ItineraryOverviewToGuidanceScreen";
import GetBackToRecentTorchAddress from "../itinerary/GetBackToRecentTorchAddress";
import TorchNotAvailableScreen from "../itinerary/TorchNotAvailableScreen";
import ConfirmationLeavingItinerary from '../itinerary/ConfirmationLeavingItinerary';
import GoingToCloseParking from "../itinerary/GoingToCloseParking";
import UserResearchChoice from '../userIntroduction/UserResearchChoice';
import ParkingResearchFromIntro from '../userIntroduction/ParkingResearchFromIntro';
import InteractiveBox from "../cocosupporter/InteractiveBox";
import AddAddressPopup from '../popup/AddAddressPopup';
import ModificationContainer from "../userMenu/ModificationContainer";
import MustLoginScreen from "../userMenu/MustLoginScreen";
import WrapperLiveViewZone from "../markers/WrapperLiveViewZone";
import ZoneWithRegulationsButton from "../liveviewZone/ZoneWithRegulationsButton";
import NavMenu from '../navBar/NavMenu'
import UserMenu from '../userMenu/UserMenu'
import LegendContainer from '../legend/LegendContainer'
import SparkedOnStreetSuccessScreen from "../itinerary/SparkedOnStreetSuccessScreen";
import InstallationLegendIphone from "../installation/InstallationLegendIphone";
import TorchErrorPopup from "../popup/TorchErrorPopup";
import ParkingLoader from "../loaders/ParkingLoader";
import WrapperLvzDirections from "../markers/WrapperLvzDirections";
import WrapperPriceArea from "../priceArea/WrapperPriceArea";


//REDUX SELECTORS
import { userLocationSelector } from '../markers/state/userLocationSelector'
import { walkingCircleSelector } from "../markers/state/walkingCircleSelector";
import { aroundMeModeSelector } from "../global/state/aroundMeModeSelector";
import { mapCenterSelector } from "../map/state/mapCenterSelector";
import { showTrafficSelector } from '../map/state/showTrafficSelector'
import { showPriceAreaSelector } from '../map/state/showPriceAreaSelector'
import { displayParkingSpecificDetailPanelSelector } from '../detailsPanel/state/displayParkingSpecificDetailPanelSelector'
import { displayLvzSpecificDetailPanelSelector } from '../detailsPanel/state/displayLvzSpecificDetailPanelSelector'
import { displayAskGeolocationPanelSelector } from '../detailsPanel/state/displayAskGeolocationPanelSelector.js'
import { filterModeActiveSelector } from "../filters/state/filterModeActiveSelector";
import { displayCityInformationsDetailsPanelSelector } from "../detailsPanel/state/displayCityInformationsDetailsPanelSelector";
import { mapFilterTrackersSelector } from "../filters/state/mapFilterTrackersSelector";
import { parkingMarkerIdSelector } from '../markers/state/parkingMarkerIdSelector'
import { displayTimeToParkContainerSelector } from "../sparkedMode/state/displayTimeToParkContainerSelector";
import { sparkedModeSelector } from "../global/state/sparkedModeSelector";
import { showParkingInProgressPopUpSelector } from '../popup/state/showParkingInProgressPopUpSelector'
import { itineraryModeSelector } from "../global/state/itineraryModeSelector";
import { displayDestinationNotReachYetSelector } from "../itinerary/state/displayDestinationNotReachYetSelector";
import { displayItineraryOverviewSelector } from '../itinerary/state/displayItineraryOverviewSelector';
import { displayGetBackToRecentTorchAddressSelector } from "../itinerary/state/displayGetBackToRecentTorchAddressSelector";
import { displayTorchNotAvailableScreenSelector } from '../itinerary/state/displayTorchNotAvailableScreenSelector'
import { displayDetailsPanelContainerSelector } from "../detailsPanel/state/displayDetailsPanelContainerSelector";
import { displayConfirmationLeavingItinerarySelector } from '../itinerary/state/displayConfirmationLeavingItinerarySelector';
import { displayGoingToCloseParkingSelector } from '../itinerary/state/displayGoingToCloseParkingSelector';
import { displayUserResearchChoiceSelector } from '../userIntroduction/state/displayUserResearchChoiceSelector';
import { displayParkingResearchFromIntroSelector } from '../userIntroduction/state/displayParkingResearchFromIntroSelector';
import { displayInteractiveBoxSelector } from '../cocosupporter/state/displayInteractiveBoxSelector';
import { displayAddAddressPopupSelector } from "../popup/state/displayAddAddressPopupSelector";
import { displayModificationContainerSelector } from "../userMenu/state/displayModificationContainerSelector";
import { researchModeSelector } from "../global/state/researchModeSelector";
import { displayMustLoginScreenSelector } from "../userMenu/state/displayMustLoginScreenSelector";
import { displayZoneWithRegulationsButtonSelector } from '../liveviewZone/state/displayZoneWithRegulationsButtonSelector'
import { displayItinerarySelector } from "../itinerary/state/displayItinerarySelector";
import { displaySparkedOnStreetSuccessSelector } from "../itinerary/state/displaySparkedOnStreetSuccessSelector";
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector';
import { displayInstallationLegendIphoneSelector } from '../installation/state/displayInstallationLegendIphoneSelector';
import { displayTorchErrorPopupSelector } from "../popup/state/displayTorchErrorPopupSelector";
import { displayParkingLoaderSelector } from "../loaders/state/displayParkingLoaderSelector";


//REDUX ACTIONS
import { updateUserLocation } from '../markers/state/userLocationAction'
import { updateWalkingCircle } from '../markers/state/walkingCircleAction'
import { updateLabelPosition } from '../markers/state/labelPositionAction'
import { toggleAroundMeMode } from '../global/state/aroundMeModeAction'
import { updateParkingMarkerData } from '../markers/state/parkingMarkerDataAction'
import { updateEvhMarkerData } from '../markers/state/evhMarkerDataAction'
import { updatePmrMarkerData } from '../markers/state/pmrMarkerDataAction'
import { updateOnstreetCommonMarkerData } from '../markers/state/onstreetCommonMarkerDataAction'
import { updateOnstreetDeliveryMarkerData } from '../markers/state/onstreetDeliveryMarkerDataAction'
import { storeLiveParkingsAvailabilities } from '../global/state/liveParkingAvailabilitiesAction'
import { updateFrontStyleParking } from '../markers/state/frontStyleParkingAction'
import { updateFrontStyleEvh } from '../markers/state/frontStyleEvhAction'
import { updateFrontStylePmr } from '../markers/state/frontStylePmrAction'
import { updateFrontStyleOnstreetCommon } from '../markers/state/frontStyleOnstreetCommonAction'
import { updateFrontStyleOnstreetDelivery } from '../markers/state/frontStyleOnstreetDeliveryAction'
import { updateFrontStyleSparkedCoco } from '../markers/state/frontStyleSparkedCocoAction'
import { updateFrontStyleLvz } from '../markers/state/frontStyleLvzAction'
import { updateMarkerId } from "../markers/state/parkingMarkerIdAction"
import { displayTorchButton } from '../torchButton/state/torchButtonAction'
import { updateTorchJson } from '../itinerary/state/torchJsonAction'
import { hideEvhInfoWindow } from "../markers/state/evhInfoWindowAction"
import { hideOnstreetDeliveryInfoWindow } from '../markers/state/onstreetDeliveryInfoWindowAction'
import { updateLvzMarkerData } from "../markers/state/lvzMarkerDataAction";
import { updateLvzMarkerRealTime } from '../markers/state/lvzMarkerRealTimeAction'
import { showZoneWithRegulationsButton, hideZoneWithRegulationsButton } from '../liveviewZone/state/displayZoneWithRegulationsButtonAction'
import { toggleResearchMode } from '../global/state/researchModeAction'
import { toggleExpandedDetailPanel } from '../detailsPanel/state/detailsPanelExpandedAction'
import { showCocoSearchMarker } from '../markers/state/cocoSearchMarkerAction'
import { toggleUserResearchChoice } from '../userIntroduction/state/displayUserResearchChoiceAction';
import { updateCocoLoaderState } from '../userIntroduction/state/cocoLoaderStateAction'
import { toggleParkingLoader } from '../loaders/state/displayParkingLoaderAction'
import { storeLvzInfo, resetLvzInfo } from '../detailsPanel/state/lvzInfoAction'


//UTILS
import { calculateLabelPosition , isAroundMeModeActive, findMarkerStatus } from '../global/MapUtils';
import { getParkingData, getLocationSpots, getParkingsSpotsAvailabilities, fetchCustomMarkersFromAirtable } from '../api/mapApi';
import { getRevealSlots } from '../api/itineraryApi'
import { getLvzStaticAndDynamicData, getClosestAvailableLvz} from '../api/liveviewZoneApi'
import { addDataToUserSessionInDB } from '../api/userTrackingApi.js'
import { getCustomerInLS, storeUserLocationInLS } from '../global/GlobalUtils';
import * as global from '../global/globalVariable'


//STYLE
import './Map.css'
import {ReactComponent as Viewfinder} from '../images/viseur.svg';

//JSON
import mapStyles from "./mapStyles.json";
import mapBlueWaterStyles from "./mapBlueWaterStyles.json";
import jsonZoomUIStatuses from '../zoom/jsonZoomUIStatuses.json'
import typeOfMarkers from '../zoom/typeOfMarkers.json'
import { updateCustomMarkers } from "../markers/state/customMarkersActions.js";
import WrapperCustomMarkers from "../markers/WrapperCustomMarkers.js";

export const Map = React.memo((props) => {
    const config = useSelector((state) => state.config)

    //HARDCODED PATH FOR LVZ
    const pathsParkingAbbePoupon = [
        {lat: 48.1975599, lng: 3.2860394},
        {lat: 48.1975384, lng: 3.2857377},
        {lat: 48.1971737, lng: 3.2857752},
        {lat: 48.1971898, lng: 3.2860971},
        {lat: 48.1975599, lng: 3.2860394}
    ]

    const pathsParkingVoirieSens = [
        {lng: 3.2831706, lat: 48.197537},
        {lng: 3.2829466, lat: 48.1975146},
        {lng: 3.283062,  lat: 48.197023},
        {lng: 3.2833006, lat: 48.1970408},
        {lng: 3.2832417, lat: 48.1972964},
        {lng: 3.2831706, lat: 48.197537},
    ]

    //global variable for Map.js
    let loadedCityzones = [] //all city zones that have already been loaded
    let evhMarkerDataObject = {} //all datas of evh markers (zoneId, lat, lng, charge...)
    let pmrMarkerDataObject = {} //all datas of pmr markers (zoneId, lat, lng)
    let onstreetCommonMarkerDataObject = {} //all datas of common street markers (zoneId, polygon)
    let onstreetDeliveryMarkerDataObject = {} //all datas of delivery street markers (zoneId, polygon)

    //STATES
    const [mapCenterLocal, setMapCenterLocal] = useState(null)
    //all states to display markers style based on zoom
    const [frontStyleParking, setFrontStyleParking] = useState(2)
    const [frontStyleSparkedCoco, setFrontStyleSparkedCoco] = useState(1)
    const [frontStylePmr, setFrontStylePmr] = useState(0)
    const [frontStyleEvh, setFrontStyleEvh] = useState(0)
    const [frontStyleLvz, setFrontStyleLvz] = useState(0)
    const [frontStyleOnstreetCommon, setFrontStyleOnstreetCommon] = useState(0)
    const [frontStyleOnstreetDelivery, setFrontStyleOnstreetDelivery] = useState(0)

    //to update state using array from typeOfMarkers.json
    const methodMap = { 
        "parking": setFrontStyleParking,
        "sparkedCoco": setFrontStyleSparkedCoco,
        "pmr": setFrontStylePmr,
        "evh": setFrontStyleEvh,
        "common": setFrontStyleOnstreetCommon,
        "delivery": setFrontStyleOnstreetDelivery, 
        "liveviewZone": setFrontStyleLvz,       
    };

    const actualZoomMap = { 
        "pmr": 0,
        "evh": 0,
        "common": 0,
        "delivery": 0,        
    };
    
    const zoomMapType = ["pmr", "evh", "common", "delivery"]

    //REFERENCES
    const nodeRefParkingSpecific = useRef(null)
    const nodeRefLvzSpecific = useRef(null)
    const nodeRefAskLocationPanel = useRef(null)
    const nodeRefItineraryPanel = useRef(null)
    const nodeRefSparkedPanel = useRef(null)
    const previousZoomStatus = useRef(null)
    const previousUserLocation = useRef({lat: 48.8566, lng:  2.3522})
    const userLocationOnLoad = useRef(true)
    const zoomStatus = useRef(null)
    const mapRef = useRef(null) //to store the map
    const mapHeightRef = useRef("80vh") //to keep and update map's height
    const mapStyleRef = useRef(mapStyles)
    const parkingMarkerIdRef = useRef(null)
    const livezoneIdRef = useRef([])
    const lvzPolygonArrayRef = useRef([]) //to store all lvz polygon path as marker to be used to know if user is in one of those polygons
    const appActiveRef = useRef(true)

    //map options
    let options = {
        styles: mapStyleRef.current,
        gestureHandling: "greedy",
        disableDefaultUI: true,
    };
    
    //SELECTORS
    const userLocation = useSelector(userLocationSelector)
    const walkingCircle = useSelector(walkingCircleSelector)
    const aroundMeMode = useSelector(aroundMeModeSelector)
    const mapCenter = useSelector(mapCenterSelector)
    const displayCityInformationsDetailsPanel = useSelector(displayCityInformationsDetailsPanelSelector)
    const showTraffic = useSelector(showTrafficSelector)
    const displayParkingSpecificDetailPanel = useSelector(displayParkingSpecificDetailPanelSelector)
    const displayLvzSpecificDetailPanel = useSelector(displayLvzSpecificDetailPanelSelector)
    const displayAskGeolocationPanel = useSelector(displayAskGeolocationPanelSelector)
    const filterModeActive = useSelector(filterModeActiveSelector)
    const mapFilterTrackers = useSelector(mapFilterTrackersSelector)
    const parkingMarkerId = useSelector(parkingMarkerIdSelector)
    const displayTimeToParkContainer = useSelector(displayTimeToParkContainerSelector)
    const sparkedMode = useSelector(sparkedModeSelector)
    const showParkingInProgressPopUp = useSelector(showParkingInProgressPopUpSelector)
    const itineraryMode = useSelector(itineraryModeSelector)
    const displayDestinationNotReachYet = useSelector(displayDestinationNotReachYetSelector)
    const displayItineraryOverview = useSelector(displayItineraryOverviewSelector)
    const displayGetBackToRecentTorchAddress = useSelector(displayGetBackToRecentTorchAddressSelector)
    const displayTorchNotAvailableScreen = useSelector(displayTorchNotAvailableScreenSelector)
    const displayDetailsPanelContainer = useSelector(displayDetailsPanelContainerSelector)
    const displayConfirmationLeavingItinerary = useSelector(displayConfirmationLeavingItinerarySelector)
    const displayGoingToCloseParking = useSelector(displayGoingToCloseParkingSelector)
    const displayUserResearchChoice = useSelector(displayUserResearchChoiceSelector)
    const displayParkingResearchFromIntro = useSelector(displayParkingResearchFromIntroSelector)
    const displayInteractiveBox = useSelector(displayInteractiveBoxSelector)
    const displayAddAddressPopup = useSelector(displayAddAddressPopupSelector)
    const displayModificationContainer = useSelector(displayModificationContainerSelector)
    const researchMode = useSelector(researchModeSelector)
    const displayMustLoginScreen = useSelector(displayMustLoginScreenSelector)
    const displayZoneWithRegulationsButton = useSelector(displayZoneWithRegulationsButtonSelector)
    const displayItinerary = useSelector(displayItinerarySelector)
    const displaySparkedOnStreetSuccess = useSelector(displaySparkedOnStreetSuccessSelector)
    const trackingIdAndAnonymousLocal = useSelector(trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const displayInstallationLegendIphone = useSelector(displayInstallationLegendIphoneSelector)
    const displayTorchErrorPopup = useSelector(displayTorchErrorPopupSelector)
    const displayParkingLoader = useSelector(displayParkingLoaderSelector)
    const showPriceArea = useSelector(showPriceAreaSelector)

    const dispatch = useDispatch()


    useEffect(() => {
        //to find the position of the walking label based on the  user location and the circle radius
        var result = calculateLabelPosition(userLocation, walkingCircle)
        dispatch(updateLabelPosition(result))
    }, [userLocation, walkingCircle, dispatch])


    useEffect(() => {
        if(mapRef.current){

            //update aroundMeMode when userLocation changes  (included switch between BackToUserLocationButton and SparkedButton)
            isAroundMeModeActive(mapRef.current, userLocation)
            .then(resp=>{
                if(resp !== aroundMeMode){
                    dispatch(toggleAroundMeMode(resp))
                }
            })

            //TO DO => uncomment if we want to center map on userLocation while user is moving
            //to center map on userLocation if aroundMeMode is true
            // if(aroundMeMode){
            //     mapRef.current.panTo(userLocation);
            // }
        }
    }, [userLocation, aroundMeMode])


    useEffect(() => {
        //to check if there is an existing session id and userId
        if(trackingIdAndAnonymousLocal[0] !== "" && trackingSessionId){
            //to add informations about user position in DB
            let dataTracking = {
                lat : userLocation.lat,
                lng : userLocation.lng,
            }
            //to only save userlocation once when the component is mounted
            //userLocationOnLoad is set to false after the fisrt loading
            if(userLocationOnLoad.current){
                userLocationOnLoad.current = false
                addDataToUserSessionInDB("geolocations", trackingIdAndAnonymousLocal[1], trackingIdAndAnonymousLocal[0], trackingSessionId, dataTracking)  
            } 
            //to only save userLocation in DB if the new userLocation from redux is different from the previous one.
            if(userLocation.lat !== previousUserLocation.current.lat && userLocation.lng !== previousUserLocation.current.lng){
                addDataToUserSessionInDB("geolocations", trackingIdAndAnonymousLocal[1], trackingIdAndAnonymousLocal[0], trackingSessionId, dataTracking)  
            }
        }

    }, [userLocation, trackingIdAndAnonymousLocal, trackingSessionId])


    useEffect(() => {
        if (!mapRef.current) return;
        let center = mapRef.current.getCenter().toJSON()
        displayLocationSpots(center)
    }, [mapFilterTrackers, filterModeActive])


    useEffect(() => {
        if(researchMode.value){
            mapRef.current.setZoom(16)
        }
    }, [researchMode])


    //to fetch parking data and lvz data when we get the actual user location
    useEffect(async () => {
        if(props.receivedUserLocation){
            
            //retrieves the address parameter of the url
            let queryAddress = new URLSearchParams(window.location.search).get("address")

            //pan to user location when getting it from getCurrentLocation in app.js 
            //the centre of the map is only updated if there is no address in the url
            if(mapRef.current && queryAddress === null){
                mapRef.current.panTo(userLocation)
            }
            await fetchParkingData(userLocation)
            dispatch(toggleParkingLoader(false))
            dispatch(updateCocoLoaderState())
            await fetchLvzData(userLocation)
            dispatch(updateCocoLoaderState())
        }
    }, [props.receivedUserLocation]);


    //to update the position of the marker showing user position if he moves
    function trackLocation() {
        navigator.geolocation.watchPosition(
            //on success function
            async function(position) {
                if(position.coords.latitude !== previousUserLocation.current.lat && position.coords.longitude !== previousUserLocation.current.lng){
                    let location = {
                        lat : position.coords.latitude,
                        lng : position.coords.longitude
                    }
                    await dispatch(updateUserLocation(location))
                    storeUserLocationInLS(location)                    
                    previousUserLocation.current = {lat: position.coords.latitude, lng: position.coords.longitude}
                }
            }, 
    
            //on error function
            console.log('Geolocation is not enabled. Please enable to use this feature'), 
            
            //options
            { enableHighAccuracy: true});
    }

    //to start research mode (display cocosearchMarker and expandedDetailPanel) from
    function launchResearchModeFromAddressFromUrl(){
        //to get address params from url when map is initialize
        let queryAddress = new URLSearchParams(window.location.search).get("address")
        //to launch research mode from queryAddress if there is params in the url
        if(queryAddress !== null){
            dispatch(toggleUserResearchChoice(false))
            let request = {
                query: queryAddress,
                fields: ['name', 'geometry'],
            };
            var service = new window.google.maps.places.PlacesService(mapRef.current);
            service.findPlaceFromQuery(request, async function(results, status) {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    let location = {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}
                    await fetchParkingData(location)
                    dispatch(toggleParkingLoader(false))
                    mapRef.current.panTo(location)
                    dispatch(showCocoSearchMarker(location))
                    dispatch(toggleResearchMode({value: true, location: location}))
                    dispatch(toggleExpandedDetailPanel(true))
                }
            });
        }
    }

    //to know if the app is active or not
    //storing the result in appActiveRef
    //used no to fetch lvzData if app is inactive
    function handleActivity(e){
        if(e.type === "focus"){
            appActiveRef.current = true
        } else if(e.type === "blur"){
            appActiveRef.current = false
        }
    }


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////                ZOOM RELATED FUNCTION                  ///////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    useEffect(() => {
        dispatch(updateFrontStyleParking(frontStyleParking))
    }, [frontStyleParking, dispatch])


    useEffect(() => {
        dispatch(updateFrontStyleEvh(frontStyleEvh))
        //to hide evhinfowindow if frontStyle changed to 1
        if(frontStyleEvh === 1){
            dispatch(hideEvhInfoWindow())
        }
    }, [frontStyleEvh, dispatch])


    useEffect(() => {
        dispatch(updateFrontStylePmr(frontStylePmr))
    }, [frontStylePmr, dispatch])


    useEffect(() => {
        dispatch(updateFrontStyleLvz(frontStyleLvz))
    }, [frontStyleLvz, dispatch])


    useEffect(() => {
        dispatch(updateFrontStyleOnstreetCommon(frontStyleOnstreetCommon))
    }, [frontStyleOnstreetCommon, dispatch])


    useEffect(() => {
        dispatch(updateFrontStyleOnstreetDelivery(frontStyleOnstreetDelivery))
        //to hide delivery infowindow if frontStyle === 0
        if(frontStyleOnstreetDelivery === 0){
            dispatch(hideOnstreetDeliveryInfoWindow())
        }
    }, [frontStyleOnstreetDelivery, dispatch])


    useEffect(() => {
        dispatch(updateFrontStyleSparkedCoco(frontStyleSparkedCoco))
    }, [frontStyleSparkedCoco, dispatch])


    //based on zoom and marker's thresholds updating marker status for UI 
    function updateZoomStatus(zoom){
        zoomMapType.forEach((type)=>{
            let zoomThresholdsArray = jsonZoomUIStatuses[type].zoom
            let iconsArray = jsonZoomUIStatuses[type].icons
            // if zoom trigger is passed, then launch the right status
            let status = findMarkerStatus(zoomThresholdsArray, iconsArray, zoom) // 0 1 or 2
            actualZoomMap[type]=status;
        })
    }


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////               MARKERS RELATED FUNCTION               ////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // //when map is initialized, fetching all datas from parkings around userLocation. Storing them in parkingMarkerData
    useEffect(() => {

        window.addEventListener('blur', handleActivity)
        window.addEventListener('focus', handleActivity)

        fetchParkingData()
        fetchLvzData(userLocation)

        const intervalParking = setInterval(() => {
            updateParkingsSpotsAvailabilities();
        }, 60 * 1000);

        const intervalLvz = setInterval(() => {
            updateLiveviewZoneAvailabilities();
        }, 15 * 1000);

        return () => {
            clearInterval(intervalParking)
            clearInterval(intervalLvz)
        };
    }, [])

    useEffect(() => {
        parkingMarkerIdRef.current = parkingMarkerId
    }, [parkingMarkerId])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const source = urlParams.get("source");
        // Fetch custom markers only if launched via QR code (source exists)        
        if (source) {
            fetchCustomMarkersData(source, dispatch);
        }
    }, []);

    async function fetchLvzData(center){
        let array = []
        await getLvzStaticAndDynamicData(center, array)
            .then(({ results }) => {
                if (results !== -1) {
                    const lvz_static = results.lvz_static
                    const lvz_dynamic = results.lvz_dynamic
                    dispatch(updateLvzMarkerData(lvz_static)) 
                    Object.keys(lvz_static).forEach(zone=>{
                        if(lvz_static[zone].entity_type === "parking" && lvz_static[zone].path_to_regulation){
                            let polygon = new window.google.maps.Polygon({ 
                                paths: lvz_static[zone].polygon, 
                                id: lvz_static[zone].lvz_id, 
                                zoneType: lvz_static[zone].path_to_regulation, 
                                name: lvz_static[zone].name, 
                                type: lvz_static[zone].entity_type
                            });
                            lvzPolygonArrayRef.current.push(polygon)
                        }
                    })

                    dispatch(updateLvzMarkerRealTime(lvz_dynamic))
                    Object.keys(lvz_dynamic).forEach(zone => {
                        array.push(zone)
                    })
                }
            })
        livezoneIdRef.current = array.filter(function(item, pos, self) {
            return self.indexOf(item) === pos;
        }) 
    }

    async function updateLiveviewZoneAvailabilities(){
        //to fecth lvzData only if the app is active
        if(appActiveRef.current){
            let center = mapRef.current.getCenter().toJSON()
            await fetchLvzData(center)
        } 
    }

    const fetchParkingData = async (location) => {
        //to request parking data from location if there is one
        //else request based on userLocation
        let locationRequest
        if(location){
            locationRequest = location
        } else {
            locationRequest = userLocation
        }
        await getParkingData(locationRequest)
        .then(async function(response){
            if(response !== undefined && response.results !== -1){
                //set markersIds with the id of all fetched parking
                await dispatch(updateParkingMarkerData(response.results.parkings))
                let array = parkingMarkerId
                response.results.parkings.forEach((parking)=>{
                    array.push(parking.index)
                })
                let uniqueArrayMarkersIds = array.filter(function(item, pos, self) {
                    return self.indexOf(item) === pos;
                })
                dispatch(updateMarkerId(uniqueArrayMarkersIds))
                await getParkingsSpotsAvailabilities(uniqueArrayMarkersIds)
                .then(function(response){
                    dispatch(storeLiveParkingsAvailabilities(response.results)) 
                })
            }
        })
    };

                      
    async function updateParkingsSpotsAvailabilities(){
        await getParkingsSpotsAvailabilities(parkingMarkerIdRef.current)
        .then(function(response){
            if(response){
                dispatch(storeLiveParkingsAvailabilities(response.results)) 
            }
        })
    }


    async function displayLocationSpots(center){
        const respGetLocationSpots = await getLocationSpots(center, loadedCityzones)
        if(respGetLocationSpots !== undefined && respGetLocationSpots.results !== -1){
            let zoneData = respGetLocationSpots.results.cityzones_to_load
            //to store the actual surrounding zone (to be used in marker component to hide or show the markers)
            let surroundingCityZone = respGetLocationSpots.results.surrounding_cityzones
            //adding to loadedCityzones the new zone we fetch (only the id)
            loadedCityzones = [...loadedCityzones, ...respGetLocationSpots.results.cityzones_to_load_ids]
            Object.keys(zoneData).forEach((zone)=>{
                Object.keys(zoneData[zone]).forEach((type)=>{
                    if(type === "e_charge"){
                        evhMarkerDataObject ={...evhMarkerDataObject, [zone]: zoneData[zone].e_charge}
                    }
                    else if (type === "prm"){
                        pmrMarkerDataObject ={...pmrMarkerDataObject, [zone]: zoneData[zone].prm}
                    } 
                    else if(type === "common"){
                        onstreetCommonMarkerDataObject ={...onstreetCommonMarkerDataObject, [zone]: zoneData[zone].common}
                    } 
                    else if(type === "delivery"){
                        onstreetDeliveryMarkerDataObject ={...onstreetDeliveryMarkerDataObject, [zone]: zoneData[zone].delivery}
                    }
                })
            })

            let zoom = mapRef.current.getZoom()
            
            updateZoomStatus(zoom)
            
            if(filterModeActive && zoom >= 16){
                if(mapFilterTrackers[3]){
                    actualZoomMap["pmr"]= 1 ;
                    dispatch(updateFrontStylePmr(1))
                }
                if (mapFilterTrackers[2]){
                    actualZoomMap["evh"]= 1 ;
                    dispatch(updateFrontStyleEvh(1))
                }
            } else {
                //to reactivate normal behavior on zoom for prm and evh markers
                dispatch(updateFrontStyleEvh(actualZoomMap["evh"]))
                dispatch(updateFrontStylePmr(actualZoomMap["pmr"]))
            }

            //to update actualZoomMap to re-render marker only if there are visible
            if(actualZoomMap.evh!== 0){
                dispatch(updateEvhMarkerData({data: evhMarkerDataObject, surroundingCityZone: surroundingCityZone}))
            }
            if(actualZoomMap.pmr!== 0){
                dispatch(updatePmrMarkerData({data: pmrMarkerDataObject, surroundingCityZone: surroundingCityZone}))
            }            
            if(actualZoomMap.delivery!== 0){
                dispatch(updateOnstreetDeliveryMarkerData({data: onstreetDeliveryMarkerDataObject, surroundingCityZone: surroundingCityZone}))
            }            
            if(actualZoomMap.common!== 0){
                dispatch(updateOnstreetCommonMarkerData({data: onstreetCommonMarkerDataObject, surroundingCityZone: surroundingCityZone}))
            }            
        }
    }

    async function fetchCustomMarkersData(source) {    
        const markers = await fetchCustomMarkersFromAirtable(source);
        dispatch(updateCustomMarkers(markers));  
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
    ////////////////////////////////               ITINERARY RELATED FUNCTION                /////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    useEffect(() => {
        //to update map style if itineraryMode (torch) is active
        if(itineraryMode || !config.parking_list){
            //update map size to fit the entire screen
            mapHeightRef.current = "100vh"
            //switch map style to a lighter color mode
            mapStyleRef.current = mapBlueWaterStyles
        } else {
            if(mapRef.current){
                mapStyleRef.current = mapStyles
                mapHeightRef.current = "80vh"
                mapRef.current.setZoom(15)
            }
        }
    }, [itineraryMode])
    

    // To display the torch if there is data on available slots at the user's location
    const updateRevealSlots = async (position) => {
        try {
            const resp = await getRevealSlots({ lat: position.lat, lng: position.lng });

            // Ensure resp is valid before accessing its properties
            if (!resp || typeof resp !== "object" || !resp.results) {
                dispatch(displayTorchButton(false));
                dispatch(updateTorchJson({}));
                return;
            }

            // to check for revealSlots availabilities only if itinerary isn't active
            if (!itineraryMode) {
                if (resp.results !== -1 && resp.results.total_number_of_spots > 0) {
                    dispatch(displayTorchButton(true));
                    dispatch(updateTorchJson(resp.results));
                } else {
                    dispatch(displayTorchButton(false));
                    dispatch(updateTorchJson({}));
                }
            }
        } catch (error) {
            dispatch(displayTorchButton(false));
            dispatch(updateTorchJson({}));
        }
    };
    


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////   
    //////////////////////////////////                MAP RELATED FUNCTIONS                ///////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    
    async function handleIdleChanged(){
        if (!mapRef.current) return;
        let center = mapRef.current.getCenter().toJSON()
        setMapCenterLocal(center)
        displayLocationSpots(center)
        if(!itineraryMode){
            //Display the torch button in the absence of LVZ nearby
            getClosestAvailableLvz(center)
            .then(async resp=>{
                //if there isn't any close LVZ, we call updateRevealSlots()
                //to check the existence of itinerary
                //and display the torch button if there is one
                if(!resp.exists || resp === "error"){
                    //to detect that data is available for the torch 
                    updateRevealSlots(center)
                    dispatch(resetLvzInfo())

                } else {
                    //hide torch button if there is LVZ nearby
                    dispatch(displayTorchButton(false))
                    dispatch(updateTorchJson({}))

                    //store closest lvz data in lvzInfoReducer
                    //to display this data in OriginalDetailsPanel
                    dispatch(storeLvzInfo(true, resp.lvz_details))
                }
            })          
        }
        let findOneParkingInZone = false
        //mapping through the array containing all zones paths to check if map center is include in one of those paths
        for(let item of lvzPolygonArrayRef.current){
            let isCenterContainedLvzPolygonArray = window.google.maps.geometry.poly.containsLocation(
                mapRef.current.getCenter(),
                item
            )
            //if center is include in one polygon, update redux state with polygon data (name + legend) to display those informations in ZoneWithRegulationsButton.js
            if(isCenterContainedLvzPolygonArray){
                let zoneName = item.name
                //to add "parking" to parking's name if its a parking
                if(item.type === "parking"){
                    zoneName = "Parking " + zoneName
                }
                findOneParkingInZone = true
                dispatch(showZoneWithRegulationsButton({title: zoneName, pathToRegulation: item.zoneType}))
                break
            }
        }
        if(!findOneParkingInZone){
            dispatch(hideZoneWithRegulationsButton())
        }
    }


    //function called after loading the map
    async function handleLoad(map) {

        //to store the current map
        mapRef.current = map;

        map.setCenter(userLocation)

        //user track location
        trackLocation()

        //to start research mode (display cocosearchMarker and expandedDetailPanel) from url
        launchResearchModeFromAddressFromUrl()

    }


    function handleBoundsChanged(){
        if (!mapRef.current) return;
        let zoom = mapRef.current.getZoom()

        //to check if the actual zoom is the thresholds of some, marker type
        //if true, changes marker type status to update its style
        typeOfMarkers.forEach((type)=>{
            let zoomThresholdsArray = jsonZoomUIStatuses[type].zoom
            let iconsArray = jsonZoomUIStatuses[type].icons
            // if zoom trigger is passed, then launch the right status
            let status = findMarkerStatus(zoomThresholdsArray, iconsArray, zoom) // 0 1 or 2
            methodMap[type](status);
        })

        //zoom state to display walking circle and its label
        if(zoom >= 16){
            zoomStatus.current = 0
            if(zoomStatus.current !== previousZoomStatus.current){
                dispatch(updateWalkingCircle({label: "5min", radius: 300 }))
                previousZoomStatus.current = 0
            }
        } else if (zoom === 15){
            zoomStatus.current = 1
            if(zoomStatus.current !== previousZoomStatus.current){
                dispatch(updateWalkingCircle({label: "10min", radius: 600 }))
                previousZoomStatus.current = 1
            }
        }else{
            zoomStatus.current = 2
            if(zoomStatus.current !== previousZoomStatus.current){
                dispatch(updateWalkingCircle({label: "15min", radius: 900 }))
                previousZoomStatus.current = 2
            }
        }

        //To check the distance between map's center and user location when zoom changed
        isAroundMeModeActive(mapRef.current, userLocation)
        .then(resp=>{
            if(resp !== aroundMeMode){
                dispatch(toggleAroundMeMode(resp))
            }
        })
    }

    // Reference to store the timeout to manage inactivity between center changes
    const timeoutRef = useRef(null);

    // Handle the center change event
    async function handleCenterChanged() {
        // Clear the previous timeout if there was one
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        
        // Set a new timeout that will log the center after 5 seconds of inactivity
        timeoutRef.current = setTimeout(() => {
            const newCenter = mapRef.current.center
            // fetchLvzData({
            //     lat: newCenter.lat(),
            //     lng: newCenter.lng(),
            // })
        }, 5000)
    };


    return (
        <div style={{position: "relative", overflow: 'hidden', height: "100vh"}}> 

        {/* display or not viewfinder based on aroundMeMode state. The viewfinder is positioned above the map in absolute position */}
            
            <GoogleMap
                options={options}
                mapContainerStyle={{
                    width: '100vw',
                    height: mapHeightRef.current,
                }}
                onLoad={handleLoad}
                onBoundsChanged={handleBoundsChanged}
                onIdle={handleIdleChanged}
                onCenterChanged={handleCenterChanged}
                zoom={props.zoom}
                defaultCenter={mapCenter}
                id="map"
                >
                <WrapperLvzDirections/>
                {!aroundMeMode ? 
                    <Viewfinder style={{position: "absolute", left: "calc(50% - 20px)",  top: "calc(50% - 20px)", width: "40px", fill: "var(--cocoparksGreen)", zIndex: 1}}/>
                    : null
                }
                <WrapperPmrMarker/>

                <WrapperSparkedCocoMarkers/>

                <UserLocationMarker/>

                <WrapperEvhMarker/>

                <WrapperOnstreetCommonMarker/>

                <WrapperOnstreetDeliveryMarker/>

                <WrapperParkingMarkers
                    map={mapRef.current}
                /> 
            
                <WrapperFreedSpotMarker/>

                <WrapperLiveViewZone
                    map={mapRef.current}
                />
                
                <WrapperCustomMarkers map={mapRef.current} />
                <CocoSearchMarker/>

                <SparkedMarker/>

                <DirectionToDestinationPolyline />


                {itineraryMode ? 
                    <TorchMarkerItinerary
                        map={mapRef.current}
                        displayItinerary={displayItinerary}
                    />:null
                }

                { showTraffic ? <TrafficLayer/> : null }

                { showPriceArea ? <WrapperPriceArea/> : null }
                

            </GoogleMap>

            {displayZoneWithRegulationsButton.visible ? 
                <ZoneWithRegulationsButton/>
            :null}

            {displayParkingLoader ?
                <ParkingLoader/>
            :null}

            <NavBar 
                map={mapRef.current}
            />

            <NavMenu/>

            <UserMenu
                map={mapRef.current}
            />

            <LegendContainer/>

            {config.parking_list ?
                <CSSTransition
                    in={displayParkingSpecificDetailPanel}
                    timeout={800}
                    classNames="parking-specific-panel"
                    unmountOnExit
                    nodeRef={nodeRefParkingSpecific}
                >
                    <div className="parking-panel-container flex-center-column" ref={nodeRefParkingSpecific}>
                        <ParkingSpecificDetailsPanel 
                            map={mapRef.current}
                        /> 
                    </div>
                </CSSTransition>
            :null}

                <CSSTransition
                    in={displayLvzSpecificDetailPanel.value}
                    timeout={800}
                    classNames="lvz-specific-panel"
                    unmountOnExit
                    nodeRef={nodeRefLvzSpecific}
                >
                    <div className="lvz-panel-container flex-center-column" ref={nodeRefLvzSpecific}>
                            <LvzSpecificDetailsPanel
                                map={mapRef.current}
                            /> 
                    </div>
                </CSSTransition>


                <div className="detail-panel-container">
                    <DetailsPanelContainer 
                        mapCenter={mapCenterLocal} 
                        map={mapRef.current}
                        fetchLvz={fetchLvzData}
                    />
                </div>

            <CSSTransition
                in={displayAskGeolocationPanel.value}
                timeout={800}
                classNames="details-panel lvz-specific-panel"
                unmountOnExit
                nodeRef={nodeRefAskLocationPanel}
            >
                <div className="lvz-panel-container flex-center-column" ref={nodeRefAskLocationPanel}>
                    <AskGeolocationPanel></AskGeolocationPanel>
                </div>
            </CSSTransition>


            <CSSTransition
                in={itineraryMode}
                timeout={800}
                classNames="itinerary-panel"
                unmountOnExit
                nodeRef={nodeRefItineraryPanel}
                >
                    <div className="itinerary-panel-container" ref={nodeRefItineraryPanel}>
                        <ItineraryPanel/>
                    </div>
            </CSSTransition> 


            <CSSTransition
                in={sparkedMode.value}
                timeout={800}
                classNames="sparked-panel"
                unmountOnExit
                nodeRef={nodeRefSparkedPanel}
            >
                <>
                    <div className="sparked-panel-container flex-center-column" ref={nodeRefSparkedPanel}>
                        <SparkedDetailsPanel
                            map={mapRef.current}
                        />
                    </div>
                    {showParkingInProgressPopUp ? <ParkingInProgressPopup/> : null}
                </>
            </CSSTransition> 


            {!itineraryMode ?
                <>
                    <FiltersContainer
                        map={mapRef.current}
                    />
    
                    {/* <NavigationSideBar/> */}
                </> : null
            }


            {displayCityInformationsDetailsPanel ? 
                <CityInformationsDetailsPanel
                    map={mapRef.current}
                />
            :null}


            {displayTimeToParkContainer ? 
                <TimeToParkContainer/> 
            :null}
            

            {displayDestinationNotReachYet && itineraryMode ? 
                <DestinationNotReachYet 
                    map={mapRef.current}
                /> 
            :null }


            {displayConfirmationLeavingItinerary ? 
                <ConfirmationLeavingItinerary
                    map={mapRef.current}
            />
            :null}


            {displayItineraryOverview.value ?
                <ItineraryOverviewToGuidanceScreen
                    map={mapRef.current}
                />
            :null}


            {displayGetBackToRecentTorchAddress.value ? 
                <GetBackToRecentTorchAddress
                    map={mapRef.current}
                /> 
            :null}


            {displayTorchNotAvailableScreen ? 
                <TorchNotAvailableScreen
                    map={mapRef.current}
                /> 
            :null}


            {displayGoingToCloseParking ?            
                <GoingToCloseParking
                    map={mapRef.current}
                />
            :null}


            {displayUserResearchChoice && config.current_use_case_request ?
                <UserResearchChoice/>
            :null}
            

            {displayParkingResearchFromIntro ? 
                <ParkingResearchFromIntro/>
            :null}

            {/* TO DO Put back the cocosupporter when the logic 
            of the LVZ will be integrated into it */}
            {/* {displayInteractiveBox.value ?
                <InteractiveBox/>
            :null} */}

            {displayAddAddressPopup.value? 
                <AddAddressPopup/>
            :null}

            {displayModificationContainer.value?
                <ModificationContainer/>
            :null}

            {displayMustLoginScreen ? 
                <MustLoginScreen/>
            :null}

            {displaySparkedOnStreetSuccess.visible?
                <SparkedOnStreetSuccessScreen/>
            :null}

            {displayInstallationLegendIphone ? 
                <InstallationLegendIphone/>
            :null}

            {displayTorchErrorPopup ? 
                <TorchErrorPopup/>
            :null}

            
        </div>
    );
})

export default Map

