import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { customMarkersSelector } from '../markers/state/customMarkersSelector';
import CustomMarker from './CustomMarker';
import { frontStyleLvzSelector } from './state/frontStyleLvzSelector';


function WrapperCustomMarkers({ map }) {
    const customMarkers = useSelector(customMarkersSelector);
    const frontStyleLvz = useSelector(frontStyleLvzSelector);

    const markers = useMemo(() => {
        if(!map) {
            return null;
        }
        if(customMarkers.length === 0) {
            return null;
        }
        if(frontStyleLvz < 4) {
            return null;
        }
        return customMarkers.map((marker) => (
            <CustomMarker
                key={marker.id }
                position={{ lat: marker.lat, lng: marker.lng }}
                iconUrl={marker.icon}
                description={marker.description}
            />
        ));
    }, [customMarkers, frontStyleLvz]);

    return <div>{markers}</div>;
}

export default WrapperCustomMarkers;
